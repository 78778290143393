import { MouseEvent } from "react";
import { BADGES_COUNT_FOR_PRODUCT_ROWS, ProductBadgeParams, useProductBadges } from "src/hooks/product/useProductBadges";
import { Discount, ProductUnit, Quantity } from "src/redux/apiTypes";
import useLocalization from 'src/services/localization/useLocalization';
import useToggles from "src/services/toggles/useToggles";
import { getWeightLabel } from "src/utils/product/getWeightLabel";
import Badges from "../Badges/Badges";
import FavoriteButton from "../ProductTile/FavoriteButton/FavoriteButton";
import { styles } from "./ProductRow.styles";
import ProductRowAddButtons from "./ProductRowAddButtons/ProductRowAddButtons";
import ProductRowPrice from "./ProductRowPrice/ProductRowPrice";

export interface Props {
  amount: number;
  place: string;
  imageSrc: string;
  title: string;
  unit: ProductUnit;
  weight: number;
  volume: number | null;
  bundle: number
  price: number;
  discount: Discount;
  currency: string;
  quantity: Quantity;
  itemInStock: boolean;
  badgeParams: ProductBadgeParams;
  isFavorite: boolean;

  onClick(e: MouseEvent<HTMLDivElement>): void;
  onAddToCart(): void;
  onChangeAmount(amount: number): void;
  onFavoriteClick(): void;
}

const ProductRow = (props: Props) => {
  const {
    amount,
    place,
    imageSrc,
    title,
    unit,
    weight,
    volume,
    bundle,
    price,
    discount,
    currency,
    quantity,
    itemInStock,
    badgeParams,
    isFavorite,
    onClick,
    onAddToCart,
    onChangeAmount,
    onFavoriteClick,
  } = props;

  const localize = useLocalization();
  const { BUNDLES_AVAILABLE } = useToggles();

  const weightLabel = getWeightLabel({
    unit,
    volume,
    weight,
    bundle: BUNDLES_AVAILABLE ? bundle : null,
    localize,
  });
  const { novelty, hit, ...restBadges} = useProductBadges(badgeParams, BADGES_COUNT_FOR_PRODUCT_ROWS);

  return (
    <div className="ProductRow" data-marker="Product Card" data-testid="productCard" onClick={onClick}>
      <div className="ProductRow__badges">
        <Badges {...restBadges} size="micro" straightLeft />
      </div>

      <div className="ProductRow__flex">
        <img
          className='ProductRow__image'
          data-marker='Product Photo'
          src={imageSrc}
          alt={title}
        />
        <div className="ProductRow__info">
          <div className="ProductRow__title">{title}</div>
          <div className="ProductRow__weight" data-marker='Weight'>{weightLabel}</div>
        </div>
      </div>
      <div className="ProductRow__flex ProductRow__flex_right">
        <div className="ProductRow__addButtons">
          <ProductRowAddButtons
            unit={unit}
            bundle={bundle}
            amount={amount}
            quantity={quantity}
            itemInStock={itemInStock}
            place={place}
            onAddToCart={onAddToCart}
            onChangeAmount={onChangeAmount}
          />
        </div>
        <div className="ProductRow__price">
          <ProductRowPrice
            discount={discount}
            price={price}
            currency={currency}
          />
        </div>
        <FavoriteButton
          isChecked={isFavorite}
          tooltipText={localize('product_tile.favorites.manage_button')}
          onFavoriteClick={onFavoriteClick}
        />
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductRow;
