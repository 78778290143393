import css from 'styled-jsx/css';
import { phone_portrait } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  .ProductRow {
    padding: 12px 16px;
    border-bottom: 1px solid var(--colors-brandLightGrey1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    width: 100%;
    position: relative;
  }
  .ProductRow__badges {
    position: absolute;
    top: 4px;
    left: 0;
  }
  .ProductRow__flex {
    display: flex;
    align-items: center;
  }
  .ProductRow__image{
    width: 44px;
    height: 44px;
  }
  .ProductRow__info {
    margin-left: 16px;
    max-width: 290px;
    ${typography.text}
    line-height: 1.15;
    padding-right: 12px;
  }
  .ProductRow__title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: -o-ellipsis-lastline;
  }
  .ProductRow__title:hover {
    color: var(--uiColors-ProductTile-titleHoverText);
  }
  .ProductRow__weight {
    margin-top: 5px;
    color: var(--colors-doveGray);
  }
  .ProductRow__price {
    width: 88px;
    margin-left: 11px;
    margin-right: 14px;
    text-align: right;
  }

  @media ${phone_portrait} {
    .ProductRow {
      display: block;
      padding: 12px;
    }
    .ProductRow__info {
      max-width: none;
      padding-right: 0;
    }
    .ProductRow__flex_right {
      justify-content: space-between;
      margin-top: 8px;
    }
    .ProductRow__price {
      margin-left: auto;
      min-width: auto;
    }
  }
`;
